import { socialvue } from '@/config/pluginInit'
import VideoPlayer from '@/components/socialvue/videoplayer/VideoPlayer.vue'
import CicularSubmenu from '@/components/CircularSubmenu/CircularSubmenu.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Chat from '../../../Apps/Chat/Index'
import AwesomeVueStarRating from '@/components/AwesomeVueStarRating/AwesomeVueStarRating.vue'
import firebase from 'firebase'
export default {
  name: 'VideoContentScreen',
  components: {
    VideoPlayer,
    AwesomeVueStarRating,
    CicularSubmenu,
    Chat
  },
  data () {
    return {
      showPlayer: false,
      payload: null,
      showPodcastPlayer: false,
      audioSrc: '',
      videoSrc: '',
      subtitleSrc: '',
      thumbnailUrl: '',
      timeSpent: 0,
      title: '',
      liked: 0,
      isSaved: false,
      timeTotal: 0,
      averageRating: 0,
      totalUsersRated: 0,
      likes: 0,
      comments: 0,
      bookmarkColor: '',
      courseDescription: '',
      userReview: '',
      otherUserReviews: [],
      inEditMode: true,
      hasUserGivenReview: false, // TODO make it false
      starRatingData: {
        star: 0, // the rating given by user
        hasresults: false,
        hasdescription: false,
        starsize: 'xs', // [xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
        disabled: false
      },
      stillStars: {
        hasresults: false,
        hasdescription: false,
        starsize: '1x', // [xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
        disabled: true
      },
      ratingFills: {
        star_5: '',
        star_4: '',
        star_3: '',
        star_2: '',
        star_1: ''
      },
      heartColor: '',
      likedText: '',
      contentState: {
        liked: '',
        isSaved: false,
        review: ''
      },
      ratingReceived: false
    }
  },
  computed: {
    ...mapGetters([
      'getUserContent',
      'getReviews',
      'getIsLessonRated',
      'getAnalyticsState'
    ])
  },
  methods: {
    ...mapActions([
      'RESET_STATE',
      'GET_USER_CONTENT',
      'DELETE_USER_REVIEW',
      'GET_ALL_REVIEWS',
      'UPDATE_USER_CONTENT_STATE'
    ]),
    ...mapMutations([
      'setLoggedUser',
      'setPage',
      'setType',
      'setAction',
      'setName',
      'setContentId',
      'setCategory',
      'setPublisherId',
      'setPublisherName',
      'setRating',
      'setReview',
      'setLike',
      'setBookmark',
      'setTopic',
      'setLevel'

    ]),
    async setupPlayer () {
      this.showPodcastPlayer = !(this.payload.content.contentType === 'VIDEO')
      if (this.showPodcastPlayer) {
        this.audioSrc = this.payload.content.contentUrl[0]
        this.thumbnailUrl = this.payload.content.thumbnailUrl
      } else {
        this.videoSrc = this.payload.content.contentUrl[0]
        this.thumbnailUrl = null
      }
      this.subtitleSrc = this.payload.content.subtitleUrl
      this.timeSpent = this.payload.timeViewed
      this.showPlayer = true
    },
    async setupContent () {
      this.title = this.payload.content.title
      this.courseDescription = this.payload.content.description
      this.liked = this.payload.liked
      this.timeTotal = Math.floor(this.payload.content.timeTotal / 60)
      this.likes = this.payload.content.likes
      this.comments = this.payload.content.comments
      this.isSaved = this.payload.isSaved
      this.averageRating = (this.payload.content.rating.averageRating).toFixed(1)
      this.ratingFills.star_5 = Object.values(this.payload.content.rating.ratingMap)[4]
      this.ratingFills.star_4 = Object.values(this.payload.content.rating.ratingMap)[3]
      this.ratingFills.star_3 = Object.values(this.payload.content.rating.ratingMap)[2]
      this.ratingFills.star_2 = Object.values(this.payload.content.rating.ratingMap)[1]
      this.ratingFills.star_1 = Object.values(this.payload.content.rating.ratingMap)[0]
      this.totalUsersRated = this.payload.content.rating.totalUsers
      this.starRatingData.star = this.payload.rating
      this.contentState.liked = this.payload.liked
      this.contentState.isSaved = this.payload.isSaved
      if (this.liked) {
        this.heartColor = 'https://static.overlay-tech.com/assets/0615f301-23bf-45b4-8893-5d3edc5dcbea.svg'
        this.likedText = 'Liked'
      } else {
        this.heartColor = 'https://upload.wikimedia.org/wikipedia/commons/5/52/Heart_icon_red_hollow.svg'
        this.likedText = 'Like this lesson'
      }
      if (this.isSaved) {
        this.bookmarkColor = 'https://static.overlay-tech.com/assets/3ce440cd-3765-4ac7-8f3c-a3fca9059c31.svg'
      } else {
        this.bookmarkColor = require('@/assets/images/svg/bookmarks_white_18dp.svg')
      }
      if (this.payload.review) {
        this.userReview = this.payload.review
        this.hasUserGivenReview = true
        this.inEditMode = false
      }
      this.fillStarRating()
    },
    fillStarRating () {
      // TODO AFTER Itegration - you may try a for loop here
      let elem = document.getElementById('5_star')
      elem.style.width = (((this.ratingFills.star_5 * 100) / this.totalUsersRated) || 0) + '%'
      elem = document.getElementById('4_star')
      elem.style.width = (((this.ratingFills.star_4 * 100) / this.totalUsersRated) || 0) + '%'
      elem = document.getElementById('3_star')
      elem.style.width = (((this.ratingFills.star_3 * 100) / this.totalUsersRated) || 0) + '%'
      elem = document.getElementById('2_star')
      elem.style.width = (((this.ratingFills.star_2 * 100) / this.totalUsersRated) || 0) + '%'
      elem = document.getElementById('1_star')
      elem.style.width = (((this.ratingFills.star_1 * 100) / this.totalUsersRated) || 0) + '%'
      this.ratingReceived = true
    },
    changeHeart () {
      if (this.liked) {
        this.heartColor = 'https://upload.wikimedia.org/wikipedia/commons/5/52/Heart_icon_red_hollow.svg'
        this.likedText = 'Like this lesson'
        this.setLike(false)
        this.bombIsAway('contentCardClick', 'unlike')
      } else {
        this.heartColor = 'https://static.overlay-tech.com/assets/0615f301-23bf-45b4-8893-5d3edc5dcbea.svg'
        this.likedText = 'Liked'
        this.setLike(true)
        this.bombIsAway('contentCardClick', 'like')
      }
      this.contentState.liked = 1 - this.contentState.liked
      this.liked = 1 - this.liked
      this.updateUserContentState()
    },
    changeBookmark () {
      if (this.isSaved) {
        this.bookmarkColor = require('@/assets/images/svg/bookmarks_white_18dp.svg')
        this.setBookmark(false)
        this.bombIsAway('contentCardClick', 'un_bookmark')
      } else {
        this.bookmarkColor = 'https://static.overlay-tech.com/assets/3ce440cd-3765-4ac7-8f3c-a3fca9059c31.svg'
        this.setBookmark(true)
        this.bombIsAway('contentCardClick', 'bookmark')
      }
      this.isSaved = !this.isSaved
      this.contentState.isSaved = !this.contentState.isSaved
      this.updateUserContentState()
    },
    deleteReview () {
      this.DELETE_USER_REVIEW({
        contentId: this.$route.params.contentId,
        success: () => {
          this.userReview = null
          this.inEditMode = true
          this.hasUserGivenReview = false
        }
      })
    },
    editReview () {
      this.inEditMode = true
    },
    postReview () {
      this.inEditMode = false
      this.contentState = {}
      this.contentState.review = this.userReview
      this.hasUserGivenReview = true
      this.setReview(this.userReview)
      this.bombIsAway('contentCardClick', 'review')
      this.updateUserContentState()
    },
    updateUserContentState () {
      this.UPDATE_USER_CONTENT_STATE({
        contentId: this.$route.params.contentId,
        payload: this.contentState
      })
    },
    async getAllReviews () {
      this.GET_ALL_REVIEWS({
        contentId: this.$route.params.contentId,
        success: this.beautifyReviews
      })
    },
    beautifyReviews () {
      this.otherUserReviews = this.getReviews
      for (const review of this.otherUserReviews) {
        if (!review.profileUrl) review.profileUrl = require('@/assets/images/svg/placeholderprofile.svg')
        if (!review.jobTitle) review.jobTitle = 'Creative Director'
        let dateFields = review.updatedDate.split('-')
        review.updatedDate = dateFields[0] + '/' +
                              dateFields[1] + '/' +
                              dateFields[2].split(' ')[0]
      }
    },
    bombIsAway (eventType, action) {
      console.log(eventType + ' ' + action)
      this.setContentId(this.payload.content.id)
      this.setAction(action)
      this.setName(this.payload.content.title)
      this.setCategory(this.payload.content.category.name)
      this.setPublisherName(this.payload.content.publisher.name)
      this.setPublisherId(this.payload.content.publisher.id)
      this.setBookmark('false')
      this.setRating(this.payload.content.rating.averageRating)
      this.setLevel('Micro')

      console.log(this.getAnalyticsState)
      // eslint-disable-next-line no-undef
      divolte.signal(eventType, this.getAnalyticsState)
    }

  },
  mounted () {
    socialvue.index()
    this.GET_USER_CONTENT({
      contentId: this.$route.params.contentId,
      success: () => {
        this.payload = this.getUserContent
        this.setupPlayer()
        this.setupContent()
        this.getAllReviews()
      }
    })
    this.RESET_STATE()
    console.log('On CONTENT_VIEW_SCREEN')
    this.setPage('CONTENT_VIEW_SCREEN')
    // eslint-disable-next-line no-undef
    divolte.signal('pageView', this.getAnalyticsState)
  },
  created () {
    let id = this.$route.params.contentId
    id = +id
    let obj = null
    let fetchingUrl = 'title_' + id
    const remoteConfig = firebase.remoteConfig()
    remoteConfig.fetchAndActivate()
      .then(() => {
        const val = remoteConfig.getValue(fetchingUrl)
        obj = JSON.parse(val._value)
        this.courseTitle = obj.title
        this.courseDescription = obj.description
      })
      .catch(() => {
      })
  }
}
